import PropTypes from 'prop-types'

export const fluidPropType = PropTypes.shape({
  aspectRatio: PropTypes.number,
  base64: PropTypes.string,
  sizes: PropTypes.string,
  srcSet: PropTypes.string,
})

export const fixedPropType = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
  base64: PropTypes.string,
  srcSet: PropTypes.string,
})
