import React, { useContext } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import PropTypes from 'prop-types'

import { StyledImage } from './styles'
import HideImageContext from '../../contexts/HideImageContext'
import { fluidPropType, fixedPropType } from '../../constants/proptypes'

const Image = ({ fluid, fixed, objectPosition, vipImage, width, height }) => {
  const { isInvisible } = useContext(HideImageContext)

  if (!!vipImage) {
    return (
      <StyledImage {...{ isInvisible }}>
        <img
          src={vipImage}
          style={{ objectFit: 'cover', width, height }}
          alt=""
        />
      </StyledImage>
    )
  }

  return (
    <StyledImage {...{ isInvisible }}>
      {fluid && (
        <Img
          {...{ objectPosition, fluid }}
          objectFit="cover"
          fadeIn={false}
          loading="eager"
        />
      )}
      {fixed && (
        <Img {...{ objectPosition, fixed }} fadeIn={false} loading="eager" />
      )}
    </StyledImage>
  )
}

export default Image

Image.defaultProps = {
  objectPosition: '50% 50%',
}

Image.propTypes = {
  fluid: PropTypes.oneOfType(
    [fluidPropType, PropTypes.arrayOf(fluidPropType)],
    PropTypes.object,
  ),
  vipImage: PropTypes.string,
  fixed: PropTypes.oneOfType([
    fixedPropType,
    PropTypes.arrayOf(fixedPropType),
    PropTypes.object,
  ]),
  objectPosition: PropTypes.string,
}
